import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import '../../Styles/Player.css';
import Collapse from 'react-bootstrap/Collapse';
/* import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';*/
import Button from 'react-bootstrap/Button'; 

function PlayerPerformance() {

  var params = useParams();

  const player = params["player"]

  const scoresArray = [];
  const inplayArray = [];
  const [L5score, setL5Score] = useState(0);
  const [L15Score, setL15Score] = useState(0);
  const [InplayPoints, setInplayPoints] = useState(0);
  const [data, setData] = useState([])
  const [DataPlayer, setDataPlayer] = useState([])
  var [loaded, setLoaded] = useState(false)

  const [openItem, setOpenItem] = useState(null);

  const handleToggle = (itemId) => {
    setOpenItem(openItem === itemId ? null : itemId);
  };

  const getBackgroundColor = (zahl) => {
    if (zahl === 0) {
      return 'red';
    } else if (zahl >= 75) {
      return 'darkgreen';
    } else {
      // Hier können Sie eine Funktion zur Berechnung der Zwischenfarbe verwenden
      // In diesem Beispiel verwenden wir eine einfache lineare Interpolation
      const orangeAnteil = (zahl / 75) * 255;
      return `rgb(${255 - orangeAnteil}, ${orangeAnteil}, 0)`;
    }
  };

  /* Points for StocksFC */
  const ShotPoints = 1;
  const ShotOnTargetPoints = 4;
  const GoalPoints = 20;
  const AssistPoints = 12;
  const KeyPassPoints = 5;
  const InterceptionPoints = 2;
  const HitWoodworkPoints = 4;
  const PenaltyWonPoints = 10;
  const TacklePoints = 3;
  const BlockPoints = 2;
  const SuccessfulDribblePoints = 3;
  const SavePoints = 7;
  const PenaltySavedPoints = 20;
  const GoalConcededPoints = -4;
  const OwnGoalPoints = -20;
  const YellowCardPoints = -4;
  const RedCardPoints = -20;
  const PenaltyMissedPoints = -7;
  const PenaltyConcededPoints = -10;
  const DispossessedPoints = -1;
  const WinningTeamPoints = 20;
  const CleanSheetPoints = 20;

  function getStatValue(statsArray, statName) {
    // Find the object with the specified stat in the array
    const statObject = statsArray.find(item => item.stat === statName);

    // Return the statValue if found, or a default value (e.g., -1) if not found
    return statObject ? statObject.statValue : 0;
  }

  function formatGameDateTime(inputDateTime) {
    // Create a Date object using the input string
    const originalDateTime = new Date(inputDateTime);
  
    // Extract day, month, year, hours, and minutes from the Date object
    const day = originalDateTime.getDate().toString().padStart(2, '0');
    const month = (originalDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDateTime.getFullYear();
    const hours = originalDateTime.getHours().toString().padStart(2, '0');
    const minutes = originalDateTime.getMinutes().toString().padStart(2, '0');
  
    // Create the formatted string
    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }

  const calculateTotalPoints = (comp) => {
    if((comp.gameStarted == 1 && comp.fieldStatus == "ON_FIELD") ||
       (comp.gameStarted != 1 && comp.fieldStatus == "ON_FIELD") ||
       (comp.gameStarted == 1 && comp.fieldStatus == "SUBSTITUTED") ||
       (comp.gameStarted != 1 && comp.fieldStatus == "SUBSTITUTED")){       
          const score = 
            (comp.totalScoringAtt - comp.ontargetScoringAtt) * ShotPoints + 
            comp.ontargetScoringAtt * ShotOnTargetPoints +
            comp.goals * GoalPoints +
            comp.goalAssist * AssistPoints +
            getStatValue(comp.so5Score.detailedScore, "adjusted_total_att_assist") * KeyPassPoints +
            comp.interceptionWon * InterceptionPoints +
            /* comp. * HitWoodworkPoints + */
            getStatValue(comp.so5Score.detailedScore, "assist_penalty_won") * PenaltyWonPoints + 
            comp.wonTackle * TacklePoints +
            getStatValue(comp.so5Score.detailedScore, "outfielder_block") * BlockPoints + 
            getStatValue(comp.so5Score.detailedScore, "won_contest") * SuccessfulDribblePoints +
            getStatValue(comp.so5Score.detailedScore, "saves") * SavePoints +
            comp.penaltiesSaved * PenaltySavedPoints +

            /* Goals conceded only for Def and Goalk if on the pitch */
            (DataPlayer.position == "Goalkeeper" ||  DataPlayer.position == "Defender" ? comp.goalsConceded * GoalConcededPoints : 0) +
            comp.ownGoals * OwnGoalPoints +
            comp.yellowCard * YellowCardPoints +
            comp.redCard * RedCardPoints + 
            comp.penaltyKickMissed * PenaltyMissedPoints +
            getStatValue(comp.so5Score.detailedScore, "penalty_conceded") * PenaltyConcededPoints +

            /* Dispossessed: Tackle against player */
            /* comp. * DispossessedPoints + */           
            (comp.game.winner && comp.game.winner.name === DataPlayer.activeClub?.name ? WinningTeamPoints : 0) +
            (comp.game.winner && comp.game.winner.name === DataPlayer.activeNationalTeam?.name ? WinningTeamPoints : 0) + 

            /* CleanSheetPoints: 
              -only for Defenders & Goalkeepers 
              -only for the whole Team (no matter if conceded themselves or not)
              */
            (comp.cleanSheet != 0 && (DataPlayer.position == "Goalkeeper" ||  DataPlayer.position == "Defender") ? CleanSheetPoints : 0)
        return score;
      }
      else {
        return 0;
      }
  };

  const calculateInplayPoints = (comp) => {
    if((comp.gameStarted == 1 && comp.fieldStatus == "ON_FIELD") ||
       (comp.gameStarted != 1 && comp.fieldStatus == "ON_FIELD") ||
       (comp.gameStarted == 1 && comp.fieldStatus == "SUBSTITUTED") ||
       (comp.gameStarted != 1 && comp.fieldStatus == "SUBSTITUTED")){       
          const score = 
            (comp.goals  +
            comp.goalAssist +
            getStatValue(comp.so5Score.detailedScore, "adjusted_total_att_assist"))
        return score;
      }
      else {
        return 0;
      }
  };

  function calculateAverages(scores, length) {
    const relevantScores = scores.slice(0, length);
    const average = relevantScores.length > 0 ? relevantScores.reduce((sum, number) => sum + number, 0) / relevantScores.length : 0;
    return average;
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetch(`https://vercel-express-blue.vercel.app/api/v1/player/performanceStats/${player}`).then(
        res => res.json()
      ).then(
        data =>{
          //console.log(data.player.football.player.gameStats) 
          setDataPlayer(data.player.football.player)
          setData(data.player.football.player.gameStats)
          setLoaded(true)         
        }
      )
      }
      fetchData()
    }, [])      

  useEffect(() => {
    if (loaded && DataPlayer) {
      /* const scores = data.map((comp) => calculateTotalPoints(comp));
      const average = scores.length > 0 ? scores.reduce((sum, number) => sum + number, 0) / scores.length : 0; */
      setL5Score(calculateAverages(scoresArray, 5));
      setL15Score(calculateAverages(scoresArray, 15));
    }
  }, [loaded, DataPlayer, data]);
    
    {/*<ul>
      <li>Shot: </li>
      <li>Shot on Target: {comp.ontargetScoringAtt}</li>
      <li>Goal: {comp.goals}</li>
      <li>Assist: {comp.goalAssists}</li>
      <li>Key Pass</li>
      <li>Interception: {comp.interceptionWon}</li>
      <li>Hit Woodwork</li>
      <li>Penalty Won</li>
      <li>Tackle</li>
      <li>Block</li>
      <li>Successful Dribble</li>

      <li>Save</li>
      <li>Penalty Saved</li>
      <li>Goal Conceded: {comp.goalsConceded}</li>
      <li>Own Goal: {comp.ownGoals}</li>
      <li>Yellow Card: {comp.yellowCard}</li>
      <li>Red Card: {comp.redCard}</li>
      <li>Penalty Missed: {comp.penaltyKickMissed}</li>
      <li>Penalty Conceded</li>
      <li>Dispossessed</li>
      <li>Winning Team: {comp.game.winner}</li>
      <li>Clean Sheet:{comp.goalsConceded == 0 ? <p>true</p> : <p>false</p>}</li> 
    </ul>  */}

  const renderPlayer = () => {     
      return data.map((comp, index) => {
        const totalPoints = calculateTotalPoints(comp);
        // Push the totalPoints to the scoresArray
        scoresArray.push(totalPoints);

        const inplay = calculateInplayPoints(comp);
        inplayArray.push(inplay);

        return(
        <div class="col-sm-10 mb-2 player_general_card">
          <div class="card round_corners">
            <div class="card-body">
              <h5 class="card-title">{comp.game.homeTeam.name} {comp.game.homeGoals} - {comp.game.awayGoals} {comp.game.awayTeam.name}</h5>
              <p>{comp.game.competition.name}</p>
              <p>{formatGameDateTime(comp.game.date)}</p>                
              <p>{comp.gameStarted == 1 ? <>Started</> : <>Not started</>}</p>
              <p>{comp.score}</p>

              <hr></hr>
              
              {/* StocksFC Points */}
              <p>Points: <p className='performancePoints' style={{backgroundColor: getBackgroundColor(totalPoints)}}>{totalPoints}</p> <Button onClick={() => handleToggle(index)} aria-controls={`collapse-${index}`} aria-expanded={openItem === index}>See details</Button></p>        
              <p>Inplay points: {inplay}</p>
          
              <Collapse in={openItem===index}>
                <div id={`collapse-${index}`}>
                  <hr></hr>
                  <ul>
                    <li>Shot: {comp.totalScoringAtt != null ? <>{comp.totalScoringAtt - comp.ontargetScoringAtt} ({(comp.totalScoringAtt - comp.ontargetScoringAtt) * ShotPoints})</> : <>0</>}</li>
                    <li>Shot on Target: {comp.ontargetScoringAtt != null ? <>{comp.ontargetScoringAtt} ({comp.ontargetScoringAtt * ShotOnTargetPoints})</> : <>0</>}</li>
                    <li>Goal: {comp.goals != null ? <>{comp.goals} ({comp.goals * GoalPoints})</> : <>0</>}</li>
                    <li>Assist: {comp.goalAssist != null ? <>{comp.goalAssist} ({comp.goalAssist * AssistPoints})</> : <>0</>}</li>
                    <li>Key Pass: {getStatValue(comp.so5Score.detailedScore, "adjusted_total_att_assist")} ({getStatValue(comp.so5Score.detailedScore, "adjusted_total_att_assist") * KeyPassPoints})</li>
                    <li>Interception: {comp.interceptionWon != null ? <>{comp.interceptionWon} ({comp.interceptionWon * InterceptionPoints})</> : <>0</>}</li>
                    <li>Hit Woodwork: Not supported yet</li>
                    <li>Penalty Won: {getStatValue(comp.so5Score.detailedScore, "assist_penalty_won")} ({getStatValue(comp.so5Score.detailedScore, "assist_penalty_won") * PenaltyWonPoints})</li>
                    <li>Tackles Won: {comp.wonTackle != null ? <>{comp.wonTackle} ({comp.wonTackle * TacklePoints})</> : <>0</>}</li>
                    <li>Block: {getStatValue(comp.so5Score.detailedScore, "outfielder_block")} ({getStatValue(comp.so5Score.detailedScore, "outfielder_block") * BlockPoints})</li>
                    <li>Successful Dribble: {getStatValue(comp.so5Score.detailedScore, "won_contest")} ({getStatValue(comp.so5Score.detailedScore, "won_contest") * SuccessfulDribblePoints})</li>

                    <hr></hr>
                    
                    <li>Save: {getStatValue(comp.so5Score.detailedScore, "saves")} ({getStatValue(comp.so5Score.detailedScore, "saves") * SavePoints})</li>
                    <li>Penalty Saved: {comp.penaltiesSaved != null ? <>{comp.penaltiesSaved}  ({comp.penaltiesSaved * PenaltySavedPoints})</> : <>0</>}</li>
                    <li>Goal Conceded: {comp.goalsConceded != null &&DataPlayer.position == "Goalkeeper" ||  DataPlayer.position == "Defender" ? <>{comp.goalsConceded} ({comp.goalsConceded * GoalConcededPoints})</> : <>0</>}</li>
                    <li>Own Goal: {comp.ownGoals != null ? <>{comp.ownGoals} ({comp.ownGoals * OwnGoalPoints})</> : <>0</>}</li>
                    <li>Yellow Card: {comp.yellowCard!= null ? <>{comp.yellowCard} ({comp.yellowCard * YellowCardPoints})</> : <>0</>}</li>
                    <li>Red Card: {comp.redCard != null ? <>{comp.redCard} ({comp.redCard * RedCardPoints})</> : <>0</>}</li>
                    <li>Penalty Missed: {comp.penaltyKickMissed} ({comp.penaltyKickMissed * PenaltyMissedPoints})</li>
                    <li>Penalty Conceded: {getStatValue(comp.so5Score.detailedScore, "penalty_conceded")} ({getStatValue(comp.so5Score.detailedScore, "penalty_conceded") * PenaltyConcededPoints})</li>
                    <li>Dispossessed: Not supported yet</li>
                    <li>Winning Team: {comp.game.winner ? <>{comp.game.winner.name == DataPlayer.activeClub.name || comp.game.winner.name == DataPlayer.activeNationalTeam?.name ? <>Yes ({WinningTeamPoints})</>:<>No</>}</> : <> No</>}</li>
                    
                    {/* Cleansheet Teamweise prüfen mit away & homegoals */}
                    <li>Clean Sheet:{comp.goalsConceded == null ? <> Yes: {CleanSheetPoints}</> : <> No: 0</>}</li>
                  </ul> 
                </div>
              </Collapse>
            </div>
          </div>
      </div>)
    })             
  }

    /* <div className='frame'>  
           
    - averageStat aus API in Zukunft nutzen
    - gameStats aus API nutzen*/
      /* <div><p>{comp.game.homeTeam.name}</p></div>
      <div><p>{comp.game.awayTeam.name}</p></div>
      <div><p>{comp.score}</p></div> 
      <div className='frame_col'><p className='frame_compname'><p className='frame_compname'></p>{comp.game.date}</p></div>
      <div className='frame_col'><p className='frame_compname'><p className='frame_compname'>{comp.game.homeTeam.name} {comp.game.homeGoals} - {comp.game.awayGoals} {comp.game.awayTeam.name}</p></p></div>
      <div className='frame_col'><p className='frame_compname'><p className='frame_compname'>{comp.score}</p></p></div>
      </div> */
    
    const textStil = {
      display: 'inline-block',
      paddingLeft: '7px', // Passen Sie den Abstand nach Bedarf an
      paddingRight: '7px', // Passen Sie den Abstand nach Bedarf an
      borderRadius: '15px',
      background: getBackgroundColor(L5score),
      color: 'black', // Die Zahl wird in Schwarz angezeigt
    };
      
  return (
    <div className='player_general'>           
      {loaded ? 
        <><h1 className='main-headline'>Player Performance</h1> 

      <div class="col-sm-10 mb-2 player_general_card">
          <div class="card round_corners">
            <div class="card-body">
              <h5 class="card-title">Average scores</h5>
              <div>Last 5 average: <p className='performancePoints' style={{backgroundColor: getBackgroundColor(L5score)}}>{Math.floor(L5score)}</p></div>
              <div>Last 15 average: <p className='performancePoints' style={{backgroundColor: getBackgroundColor(L15Score)}}>{Math.floor(L15Score)}</p></div>              
            </div>
          </div>
        </div>
        {renderPlayer()}
        </>
        : 
        <></>
      }    
    </div>
  )

}

export default PlayerPerformance;