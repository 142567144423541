import React, {useState} from 'react';
import FAQ from '../Material/FAQ';
import "../Styles/Help.css";

const Help = () => {

    const [faqs, setfaqs] = useState([
      {
        question: 'What is StocksFC Performance?',
        answer: 'It is a platform focused on provisioning information about players from StocksFC. It takes into account various statistical and gameplay factors, providing users with insights into how well a player is contributing to their portfolio team based on real-world football events and outcomes. You can see which players from which teams are available and their performance from past games.',
        open: false
      },
      {
        question: 'How much does StocksFC Performance cost?',
        answer: <p>For the time being, the use is free of charge. If you like the website and find it helpful you can support us here: <a href="/support">Support</a></p>,
        open: false
      },
      {
        question: 'Are the performance stats provided by StocksFC Performance completely reliable?',
        answer: <p>Not entirely. Currently, "Hit Woodwork" and "Dispossessed" statistics are not available. These stats contribute 4 or -1 point, respectively, so the impact on overall performance differences is not substantial.
          <br></br>In addition StocksFC does not update the data after the night of the game while our data is reviewed and updated. That can be another reason for differences. 
          <br></br>While the remaining statistics are accurate, please don't hesitate to contact us if you notice any discrepancies.You can send a message via the <a href="/contact">contact formular</a>.</p>,
        open: false
      },
      {
        question: 'Which competitions are currently available?',
        answer: <p>For the moment Premier league, Champions League, Europa League and some national competitions like the AFCON are available.</p>,
        open: false
      },
      {
        question: 'What can I do if my question has not been addressed here?',
        answer: <p>You can send a message via the <a href="/contact">contact formular</a>.</p>,
        open: false
      }
    ]);
    
    const toggleFAQ = index => {
      setfaqs(faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open
        } else {
          faq.open = false;
        }
  
        return faq;
      }))
    }
    
    
    return (      
      <div className="mainbody">
          <p className='headline_comp'>Frequently asked questions</p>
          <div className="faqs">
              {faqs.map((faq, i) => (
                  <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
              ))}
          </div>
      </div>
    );
};

export default Help;
