import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom'
import '../../Styles/Competitions.css'
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';

function Competitions() {

    const [data, setData] = useState([])
    var [loaded, setLoaded] = useState(false)

    useEffect(() => {
        fetch("https://noawag.de/public/get/competitions").then(
          res => res.json()
        ).then(
          data =>{
            console.log(data)
            setData(data)
            setLoaded(true)
          }
        )
    }, [])

    const renderComps = () => {
      return data.map(comp =>
        <div className='frame'>     
          <div className='frame_col'><p className='frame_compname'><Link to={`${comp.slug}`}>{comp.pictureUrl ? <img className='complogo' src={comp.pictureUrl} alt='competition-logo'/> : <img className='complogo' src={"https://noawag.de/assets/placeholder2.png"} alt='competition-logo'/>}</Link></p></div>
          <div className='frame_col'><p className='frame_compname'><Link to={`${comp.slug}`}><p className='frame_compname'>{comp.name}</p></Link></p></div>
          <div className='frame_col'><p className='frame_compname'>{<img className='compflag' src={comp.flagUrl} alt='flag' />}</p></div>       
        </div>)
    } 

    /* alternative with bootstrap cards */
    /* const renderComps = () => {
      return data.map(comp =>
        <div class="col-sm-12 mb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{comp.name}</h5>
              <img className='compflag' src={comp.pictureUrl} alt='flag' />
              <img className='compflag' src={comp.flagUrl} alt='flag' />
              <Link to={`${comp.slug}`} state={{ data: [comp.pictureUrl, comp.name, comp.flagUrl] }}><a class="btn btn-primary">See clubs</a></Link>
            </div>
          </div>
        </div>) 
        
    }*/
  
    return (
      <div className='mainbody'>
        <p className='headline_comp bold'>Competitions</p>
  
        <div className='row outer_frame  frame_gap'>
          {loaded ? 
          renderComps() 
          :
          <Spinner className='spinner-centered' animation="border" size="xxl"/> 
        }
        </div>
  
      </div>          
    );
          
}

export default Competitions;