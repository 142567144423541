import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Searchbar from '../../Material/Searchbar';
import '../../Styles/Player.css'
import Player from './Player';
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';

function PlayerGeneral() {

  var params = useParams();
  const player = params["player"]

  const [data, setData] = useState()
  var [loaded, setLoaded] = useState(false)

  function formatBirthday(inputBirthday) {
    // Split the input string into an array of year, month, and day
    const parts = inputBirthday.split('-');
  
    // Create a Date object using the parts
    const originalDate = new Date(parts[0], parts[1] - 1, parts[2]);
  
    // Extract day, month, and year from the Date object
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDate.getFullYear();
  
    // Create the formatted string
    const formattedBirthday = `${day}.${month}.${year}`;
  
    return formattedBirthday;
  }  

  useEffect(() => {
    const fetchWallet = async () => {
      await fetch(`https://vercel-express-blue.vercel.app/api/v1/player/general/${player}`).then(
        res => res.json()
      ).then(
        data =>{
          console.log(data) 
          setData(data.player.football.player)
          setLoaded(true)
        }
      )
    }

    fetchWallet()
    }, [])  
    
    {/* 
      - Verletzung/Suspension...
      - Born on (age)
      - Ende des Vertrags
      - alle competitions des Vereins
      - Performance Durchschnitt
      - next game (next games)
      
      - evtl. in Zukunft tabs mit preis, performance, 
    */}

    const renderPlayer = () => {
      if(data!= undefined){
        return (
          <>
          <div class="col-sm-10 mb-2 player_general_card">
            <div class="card round_corners">
              <div class="card-body">
                <h1 class="card-title">{data.matchName}</h1>

                <hr></hr>
                <img className='player_general_img' src={data.avatarPictureUrl} alt='player-img' />                
                <img className='player_general_flag' src={data.country.flagUrl} alt='nationality' />  
                <p className='player_general_txt'>Born on {formatBirthday(data.birthDay)} ({data.age})</p>   
                <p className='player_general_txt'>Position: {data.positionTyped}</p>             
                <p className='player_general_txt'>Playing status: {data.playingStatus}</p> 

                <hr></hr>
                <div>
                  <p className='player_general_txt'>{data.activeClub.name} ({data.activeClub.domesticLeague.name}: {data.activeClub.domesticLeagueRanking})</p></div>  
                  {data.activeClub.activeCompetitions.length > 0 && (
                  <div>
                    <p>Active Competitions:</p>
                    <ul>
                      {data.activeClub.activeCompetitions.map((competition, index) => (
                        <li key={index}>{competition.name}</li>
                      ))}
                    </ul>
                  </div>)}

                  {data.activeNationalTeam != undefined && (
                  <div>
                    <hr></hr>
                    <p className='player_general_txt'>National team: {data.activeNationalTeam.name}</p>
                    <p>Active Competitions:</p>
                    <ul>
                      {data.activeNationalTeam.activeCompetitions.map((competition, index) => (
                        <li key={index}>{competition.displayName}</li>
                      ))}
                    </ul>
                  </div>)}

                <hr></hr>
                <div>                      
                  <p className='player_general_txt'>Injury: {data.activeInjuries.length==0 ? <>No injury</> : <>{data.activeInjuries[0].kind}</>}</p>     
                  <p className='player_general_txt'>Suspension: {data.activeSuspensions.length==0 ? <>No suspension</> : <>{data.activeSuspensions[0].kind}</>}</p>   
                </div> 
              </div>
            </div>
          </div>         
          </>
        )                    
      } 
    }

    /*     
    <div>          
        <div><p>{data.matchName}</p></div>  
        <img className='player_img' src={data.avatarPictureUrl} alt='player-img' />
        <div><p>Born on {data.birthDay} ({data.age})</p></div>    
        <img className='player_img' src={data.country.flagUrl} alt='nationality' />   
        <div><p>{data.positionTyped}</p></div>              
        <div><p>{data.playingStatus}</p></div>    

        <div><p>{data.activeClub.name} ({data.activeClub.domesticLeague.name}: {data.activeClub.domesticLeagueRanking})</p></div>  
        {data.activeClub.activeCompetitions.length > 0 && (
          <div>
            <p>Active Competitions:</p>
            <ul>
              {data.activeClub.activeCompetitions.map((competition, index) => (
                <li key={index}>{competition.name}</li>
              ))}
            </ul>
          </div>
        )}

        {data.activeNationalTeam ? <div><p>National team: {data.activeNationalTeam.name}</p></div> : <></>}                
        {data.activeInjuries.length==0 ? <div><p>No injury</p></div> :<div><p>{data.activeInjuries[0].kind}</p></div>}       
        {data.activeSuspensions.length==0 ? <div><p>No suspensions</p></div> :<div><p>{data.activeSuspensions[0].kind}</p></div>}    
      </div>
     */

    return (
      <div className='player_general'> 
        <h1 className='main-headline'>Player</h1>    
          {loaded ? 
            renderPlayer()
            : 
            <Spinner className='spinner-centered' animation="border" size="xxl"/> 
          }  
      </div>
    )

}

export default PlayerGeneral;