import React from 'react';

function Home() {

  return (
    <div className='mainbody'>
      <div className='mainbody-text'>    
        <h1>Welcome to StocksFC Performance!</h1>      
        <h2>StocksFC Performance is the ultimate tool for StocksFC investors!</h2>
          
        <div className='container_features'>
          <br></br>
          <h2>Here are just a few reasons why our platform is a must-have for any serious investor:</h2>
          <br></br>
          <ul className='features'>
            <li className='features_individual'>Check performance of players.</li>
          </ul>
          <br></br>
          </div>
      </div>
    </div>
  )

}

export default Home;