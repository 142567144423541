import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Searchbar from '../../Material/Searchbar';
import ClubPlayers from './ClubPlayers';
import ClubFuture from './ClubFuture';

function Club() {

  var params = useParams();

  const player = params["player"]
  
  return (
    <div className='mainbody'>    
     <ClubPlayers/>
     <ClubFuture/>
    </div>
  )

}

export default Club;