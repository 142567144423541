import React, {useState, useEffect} from 'react';
import {useParams, useLocation, Link} from 'react-router-dom';
import Player from '../Players/Player';
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';

function CompClubs() {

  /* Changed from useLocation to useEffect for compName because of problem arising when opening site with link and not coming from other site with "Link to" */
  /* const location = useLocation();
  var compname = location.state.data[1] */

  var params = useParams();
  var compslug = params["competition"]

  const [data, setData] = useState([])
  const [compName, setCompName] = useState([])
  const [compImg, setCompImg] = useState([])
  const [compFlag, setCompFlag] = useState([])
  var [loaded, setLoaded] = useState(false)

  useEffect(() => {
      fetch(`https://noawag.de/public/get/clubs/${compslug}`).then(
        res => res.json()
      ).then(
        data =>{
          console.log(data)
          setData(data)
          setCompName(data[0].leagueName)
          setCompImg(data[0].compPictureUrl)
          setCompFlag(data[0].flagUrl)
          setLoaded(true)
        }
      )
  }, [])

  const renderComps = () => {
    return data.map(comp =>
      <div class="col-sm-6 mb-2">
        <div class="card round_corners">
          <div class="card-body">
            <h5 class="card-title">{comp.name}</h5>
            <img className='compflag' src={comp.pictureUrl} alt='flag' />
            <Link to={`/club/${comp.slug}`}><a class="btn btn-primary">See players</a></Link>
            {/* <a href={"/club/" + comp.slug} class="btn btn-primary">See players</a> */}
          </div>
        </div>
      </div>)
      
  }

  return (
    <div className='mainbody'>
      <p className='headline_comp bold'>{compName}</p>
      {/* <img className='compflag' src={compImg} alt='competition-logo'/> 
      <img className='compflag' src={compFlag} alt='competition-flag'/> */} 
         
      <div className='row frame_gap'>
        {loaded ? 
          renderComps() 
          :
          <Spinner className='spinner-centered' animation="border" size="xxl"/> 
        }
      </div>
    </div>          
  );

}

export default CompClubs;