import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Searchbar from '../../Material/Searchbar';
import '../../Styles/Player.css'
import PlayerGeneral from './PlayerGeneral';
import PlayerFuture from './PlayerFuture';
import PlayerPerformance from './PlayerPerformance';

function Player() {

  var params = useParams();

  const player = params["player"]
  
  return (
    <div className='mainbody'>    
      <PlayerGeneral/>
      <PlayerFuture/>
      <PlayerPerformance/>
    </div>
  )

}

export default Player;