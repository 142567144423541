import React from 'react';
import '../Styles/Support.css'

const Support = () => {
    return (
        <div className='mainbody'>
            <div className='essent-div-data'>
                <p className='headline_essential'><strong>Support StocksFC Performance</strong></p>
                <br></br>

                <p className='support_text'>If you enjoy using StocksFC Performance, there are a couple of ways that you can support our platform and help us to continue improving and developing it.</p>
                <br></br>

                {/* <p className='support_text'>One way to support Sorare Album is by using our affiliate link when you purchase new cards on Sorare. When you want to buy a new card click on the "Find on Sorare" button under a card in the <a href="/competitions/premier-league-gb-eng/2022/liverpool-liverpool">club view</a>.<br></br>By doing so, you are helping to generate a small commission. It's a simple and easy way to support us, and it doesn't cost you anything extra.</p>
                <br></br> */}

                <p className='support_text'>One way to support StocksFC Performance is by using our <a href='https://app.stocksfc.com/signup?r=f71ugRrjXnR7s85YH6AP2UxnMHL2'>affiliate link</a> when you create an account on StocksFC. 
                When you want to start with Sorare or want to recommend it to a friend you can use this link https://app.stocksfc.com/signup?r=f71ugRrjXnR7s85YH6AP2UxnMHL2 to support us. 
                <br></br>
                <br></br>
                By creating an account with our link and depositing eth on the platform, you are helping to generate a small commission for us. It's a simple and easy way to support us, and it doesn't cost you anything extra.</p>
                <br></br>

                <p className='support_text'>Another way to support StocksFC Performance is by making a donation. You can donate in the form of:</p>
                <br></br>
                <ul className='ul-support'>
                    <li className='li-support'>Buy me a coffee:</li>
                    <br></br>
                    <a href="https://www.buymeacoffee.com/sorarealbum" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" className='bmacpic' alt="Buy Me A Coffee"/></a>                   
                    <br></br>
                    <li className='li-support'>Crypto: ETH-address: 0x84BB6e8964708373bc4290BC77E48145041b290B</li>
                    <br></br>
                    <img src={"https://sorare-album.com/assets/ETH_address.jpg"} className="walletpic" alt='QR code for ETH address'/>
                    <br></br>
                    <li className='li-support'>Crypto: BTC-address: bc1q2xd57qqx7jsjq34k6evta5fmqnjzed52qmeh4u</li>
                    <br></br>
                    <img src={"https://sorare-album.com/assets/BTC_address.jpg"} className="walletpic" alt='QR code for BTC address'/>
                    <br></br>
                    <li className='li-support'>Paypal:</li>
                    <br></br>
                    <img src={"https://sorare-album.com/assets/pp_qr.jpg"} className="walletpic" alt='QR code for Paypal'/>
                    <br></br>
                </ul>
                <p className='support_text'>We are incredibly grateful for any support that you can offer, and we want to make sure that StocksFC Performance continues to be a valuable resource for StocksFC investors worldwide. <br></br>
                Thank you for your support, and we look forward to continuing to enhance your Sorare collecting experience.</p>
                <br></br>
                <p className='support_text'>To achieve this vision we welcome any feedback or suggestions that can help improving StocksFC Performance. <a href="/contact">Contact us</a></p>
                {/* <a href='mailto:support@sorare-album.com'><button className='supportbutton support_text'>Send E-Mail</button></a> */}
                <br></br>
            </div>
        </div>
    );
};

export default Support;