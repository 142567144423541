import './Styles/Main.css';
import './Styles/Navbar.css';
import './Styles/Searchbar.css';
import './Styles/Footer.css';
import './Styles/Impress.css'
import './Styles/Dataprivacy.css'

import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import React, {useEffect} from 'react';

/* Essentials */
import Home from './Components/Home';
import Navbar from './Essentials/Navbar';

/* Competition and team elements */
import Competitions from './Components/Teams/Competitions';
import CompClubs from './Components/Teams/CompClubs';
import Club from './Components/Teams/Club';
import ClubPlayers from './Components/Teams/ClubPlayers';

/* Player elements */
import PlayerOverview from './Components/Players/PlayerOverview';
import Player from './Components/Players/Player';

/* Live elements */
import Games from './Components/Live/Games';

/* Footer elements */
import Support from './Essentials/Support';
import Help from './Essentials/Help';
import About from './Essentials/About';
import Contact from './Essentials/Contact';
import Footer from './Essentials/Footer';
import Dataprivacy from './Essentials/Dataprivacy';
import Impress from './Essentials/Impress';

import Experiments from './Components/Experiments';
import ClubOverview from './Components/Teams/ClubOverview';
import InplayRewards from './Components/Rewards/InplayRewards';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    var scale = 'scale(1)';
    document.body.style.webkittransform =  scale;    // chrome, opera, safari
     document.body.style.mstransform =   scale;       // ie 9
     document.body.style.transform = scale;     // general
     document.body.style.zoom = "100%";
  }, [pathname]);

  return null;
};

function App() {

  return (
    <>
      <BrowserRouter>    
        {/* Scroll to top of every opened page (improvement: faster) */}  
        <ScrollToTop/>
        <Navbar/>
        <Routes>  
          <Route path='/' element={<Home />}></Route>

          <Route path='/competitions' element={<Competitions/>}></Route>
          <Route path='/competitions/:competition' element={<CompClubs/>}></Route>
          <Route path='/club/:club' element={<Club/>}></Route>
          <Route path='/clubs' element={<ClubOverview/>}></Route>
          
          <Route path='/players' element={<PlayerOverview/>}></Route>
          <Route path='/players/:player' element={<Player/>}></Route>

          <Route path='/inplayrewards' element={<InplayRewards/>}></Route>

          <Route path='/live' element={<Games/>}></Route>

          <Route path='/experiments' element={<Experiments/>}></Route>

          <Route path='/about' element={<About />}></Route>
          <Route path='/support' element={<Support />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/impress' element={<Impress />}></Route>
          <Route path='/help' element={<Help />}></Route>
          <Route path='/dataprivacy' element={<Dataprivacy />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;