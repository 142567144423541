import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import '../../Styles/Player.css';
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';


function InplayRewards() {

  var params = useParams();

  const player = params["player"]

  const [data, setData] = useState([])
  const [dataStats, setDataStats] = useState([])
  const [DataPlayer, setDataPlayer] = useState([])
  var [loaded, setLoaded] = useState(false)

  /* Points for inplay rewards StocksFC */
  const GoalPoints = 1;
  const AssistPoints = 1;
  const KeyPassPoints = 1;

  function getStatValue(statsArray, statName) {
    // Find the object with the specified stat in the array
    const statObject = statsArray.find(item => item.stat === statName);

    // Return the statValue if found, or a default value (e.g., -1) if not found
    return statObject ? statObject.statValue : 0;
  }


  function formatGameDateTime(inputDateTime) {
    // Create a Date object using the input string
    const originalDateTime = new Date(inputDateTime);
  
    // Extract day, month, year, hours, and minutes from the Date object
    const day = originalDateTime.getDate().toString().padStart(2, '0');
    const month = (originalDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDateTime.getFullYear();
    const hours = originalDateTime.getHours().toString().padStart(2, '0');
    const minutes = originalDateTime.getMinutes().toString().padStart(2, '0');
  
    // Create the formatted string
    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }   
    
    useEffect(() => {
        fetch(`https://noawag.de/public/get/players_details/all`).then(
          res => res.json()
        ).then(
          data =>{
            console.log(data)
            setData(data)
            setLoaded(true)
          }
        )      
        }, []) 
    
    const renderPlayer = () => {     
        return data.map((comp, index) =>
          <div class="col-sm-10 mb-2 player_general_card">
            <div class="card round_corners">
              <div class="card-body">
                <h5 class="card-title">{comp.game.homeTeam.name} {comp.game.homeGoals} - {comp.game.awayGoals} {comp.game.awayTeam.name}</h5>
                <p>{comp.game.competition.name}</p>
                <p>{formatGameDateTime(comp.game.date)}</p>                
                <p>{comp.gameStarted == 1 ? <>Started</> : <>Not started</>}</p>
                <p>{comp.score}</p> 

                <li>Goal: {comp.goals != null ? <>{comp.goals} ({comp.goals * GoalPoints})</> : <>0</>}</li>
                <li>Assist: {comp.goalAssist != null ? <>{comp.goalAssist} ({comp.goalAssist * AssistPoints})</> : <>0</>}</li>
                <li>Key Pass: {getStatValue(comp.so5Score.detailedScore, "adjusted_total_att_assist")} ({getStatValue(comp.so5Score.detailedScore, "adjusted_total_att_assist") * KeyPassPoints})</li>
              </div>
            </div>
        </div>
        )             
    }

    
    return (
      <div className='player_general'>              
        <h1 className='main-headline'>Inplay rewards</h1> 
        {loaded ?
        <DataTable value={data} stripedRows sortField="position" sortMode="multiple" className='table_gap'>  
          <Column field='displayName' header='Name' sortable/>
          <Column field='position' header='Position' sortable/>
        </DataTable>
        :
        <Spinner className='spinner-centered' animation="border" size="xxl"/> 
        /*<ColorRing
          visible={true}
          height="15vw"
          width="15vw"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#004aad','#004aad', '#004aad', '#004aad', '#004aad']}
        />*/
        }              
        </div>
    )

}

export default InplayRewards;