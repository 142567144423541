import React,{ useState, useEffect }  from 'react';
import {Link} from 'react-router-dom'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css' 
import Spinner from 'react-bootstrap/Spinner';
import { FcCancel, FcRight } from "react-icons/fc";
/* import { ColorRing } from 'react-loader-spinner';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api'; */

function PlayerOverview() {

  var [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const resetFilter = () => {
    setGlobalFilterValue('');
  };

  useEffect(() => {
    fetch(`https://noawag.de/public/get/players_details/all`)
      .then(res => res.json())
      .then(data => {
        setData(data);
        setLoaded(true);
      });
  }, []);

  const filteredData = data.filter((rowData) => {
    const globalFilterFields = ['displayName', 'clubName', 'position', 'nationalTeam'];
    return globalFilterFields.some((field) =>
      String(rowData[field]).toLowerCase().includes(globalFilterValue.toLowerCase())
    );
  });

  const nameLinkTemplate = (data) => {
    return <Link to={`/players/${data.slug}`}><p>{data.displayName}</p></Link>;
  };
  
  const clubLinkTemplate = (data) => {
    return <Link to={`/club/${data.clubSlug}`}>{data.clubName}</Link>;
  };

  const imageBodyTemplate = (data) => {
    return <Link to={`/club/${data.clubSlug}`}><img src={data.clubPictureUrl} alt={data.clubPictureUrl} className="w-6rem shadow-2 border-round img_table_players"/></Link>;
  };

  const flagBodyTemplate = (data) => {
    return <img src={data.flagUrl} alt={data.flagUrl} className="w-6rem shadow-2 border-round rounded" />;
  };
  
  const nationalTeamTemplate = (data) => {
    return (data.nationalTeam != "undefined") ? <p>{data.nationalTeam}</p> : <FcCancel size={"25px"}/>;
  };
 
  return (
    <div className='mainbody'>
      <h1 className='main-headline'>Players</h1>

      {/* <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" /> */}

      <div className="search-bar-container table_gap">
        <input
          type="text"
          className="search-bar-input"
          value={globalFilterValue}
          onChange={onGlobalFilterChange} 
          placeholder="Search player/club"/>
        <button className="reset-filter-button" onClick={resetFilter}>Reset Filter</button>     
      </div>

      {loaded ?
        <DataTable
          value={filteredData}
          className='table_gap'
          sortField="position"
          paginator rows={15}           
          currentPageReportTemplate="{first} to {last} of {totalRecords}" 
          //rowsPerPageOptions={[15, 25, 50]} 
          sortOrder={1} // Specify the sorting order (1 for ascending, -1 for descending)
          sortMode="multiple"
        >
          <Column field='displayName' body={nameLinkTemplate} header='Name' sortable />
          <Column field='position' header='Position' sortable/> 
          <Column field='clubName' body={clubLinkTemplate} header='Club' sortable/>
          <Column body={imageBodyTemplate} header=''/>
          <Column body={flagBodyTemplate} header='Nationality'/>
          <Column field='nationalTeam' body={nationalTeamTemplate} header='National team' sortable/>
        </DataTable>
        :
        <Spinner className='spinner-centered' animation="border" size="xxl" />
      }
    </div>
  );
}



export default PlayerOverview;

