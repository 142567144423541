import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Searchbar from '../../Material/Searchbar';
import '../../Styles/Player.css'
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';

function ClubFuture() {

  var params = useParams();
  var clubslug = params["club"]

  const [data, setData] = useState()
  var [loaded, setLoaded] = useState(false)

  function formatGameDateTime(inputDateTime) {
    // Create a Date object using the input string
    const originalDateTime = new Date(inputDateTime);
  
    // Extract day, month, year, hours, and minutes from the Date object
    const day = originalDateTime.getDate().toString().padStart(2, '0');
    const month = (originalDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDateTime.getFullYear();
    const hours = originalDateTime.getHours().toString().padStart(2, '0');
    const minutes = originalDateTime.getMinutes().toString().padStart(2, '0');
  
    // Create the formatted string
    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }

  useEffect(() => {
    const fetchWallet = async () => {
      await fetch(`https://vercel-express-blue.vercel.app/api/v1/games/upcoming/${clubslug}`).then(
        res => res.json()
      ).then(
        data =>{
          console.log(data) 
          setData(data.player.football.club.upcomingGames)
          setLoaded(true)
        }
      )
    }

    fetchWallet()
    }, [])  

    const renderGames = () => {
      if(data!= undefined){
        return (
          <>
          <div class="col-sm-10 mb-2 mt-3 player_general_card">
            <div class="card round_corners">
              <div class="card-body">
                <h1 class="card-title">Next games</h1> 
                 {data.map((game) => 
                  <>
                  <hr></hr>
                  <div><img src={game.homeTeam.pictureUrl} alt={game.homeTeam.name} className="w-6rem shadow-2 border-round img_table_players"/> - <img src={game.awayTeam.pictureUrl} alt={game.awayTeam.displayName} className="w-6rem shadow-2 border-round img_table_players"/></div>
                  <div>{game.homeTeam.name} - {game.awayTeam.name}</div>
                  <div>{formatGameDateTime(game.date)}</div>
                  <div>{game.competition.name}</div>
                  {/* 
                  <hr></hr>
                  <p>
                  <img src={game.homeTeam.pictureUrl} alt={game.homeTeam.name} className="w-6rem shadow-2 border-round img_table_players"/> - <img src={game.awayTeam.pictureUrl} alt={game.awayTeam.displayName} className="w-6rem shadow-2 border-round img_table_players"/>
                   | {game.homeTeam.name} - {game.awayTeam.name}
                   | {formatGameDateTime(game.date)}
                   | {game.competition.name}
                  </p>
                   */}
                  </>
                )}
              </div>
            </div>
          </div>
          </>
        )                    
      } 
    }
   
    return (
      <div className='player_general'> 
        {renderGames()}
      </div>
    )

}

export default ClubFuture;