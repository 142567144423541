import React, { useState, useEffect } from 'react';
import axios from 'axios';


const Experiments = () => {
  // Bestimmen der Hintergrundfarbe basierend auf dem Wert der Zahl
  const zahl = 85;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${dd}.${mm}.${yyyy}`;
  };

  const today = new Date();
  const currentDayOfWeek = today.getDay();
  const daysUntilLastTuesday = (currentDayOfWeek + 5) % 7; // Adjusted for last Tuesday
  const daysUntilNextMonday = (8 - currentDayOfWeek) % 7; // Days until next Monday

  const lastTuesday = new Date(today);
  lastTuesday.setDate(today.getDate() - daysUntilLastTuesday);

  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);

  const getBackgroundColor = () => {
    if (zahl === 0) {
      return 'red';
    } else if (zahl >= 75) {
      return 'darkgreen';
    } else {
      // Hier können Sie eine Funktion zur Berechnung der Zwischenfarbe verwenden
      // In diesem Beispiel verwenden wir eine einfache lineare Interpolation
      const orangeAnteil = (zahl / 75) * 255;
      return `rgb(${255 - orangeAnteil}, ${orangeAnteil}, 0)`;
    }
  };

  


  const textStil = {
    display: 'inline-block',
    paddingLeft: '7px', // Passen Sie den Abstand nach Bedarf an
    paddingRight: '7px', // Passen Sie den Abstand nach Bedarf an
    borderRadius: '15px',
    background: getBackgroundColor(),
    color: 'black', // Die Zahl wird in Schwarz angezeigt
  };
  
  return (
    <>
    <div className='mainbody'>   
      <p style={textStil}>{zahl}</p>      
      <p>Gameweek: {formatDate(lastTuesday)} - {formatDate(nextMonday)}</p>     
    </div>  
    </>
  );
};

export default Experiments;
