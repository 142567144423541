import React from 'react';
import '../Styles/About.css';

const About = () => {
    return (
        <>
        <h1 className='main-headline'>About me</h1>
        <div class="item">            
            <div class="description">
                <div className='title_about'>Welcome to StocksFC Performance!</div>
                <div className='text'>                    
                    My name is Noah, I am the creator of this platform. At this point I want to tell you a bit about myself and about StocksFC Performance. Welcome to StocksFC Performance, a project that has grown out of my passion for fantasy football.
                    <br></br>
                    <br></br>I am a 23-year-old guy from germany who started playing Sorare in October 2021 and was instantly hooked on the game. I still remember the moment when I received my first reward: Lars Stindl, the captain of Borussia Mönchengladbach. 
                    <br></br>That was my start in fantasy football games. I followed YouTube channels, blogs and websites that helped me in playing Sorare. I subscribed to the Playsharper newsletter and one day it informed me about StocksFC.
                    {/* I loved the idea and started with the 5$ beginner bonus (Link). */}
                    <br></br>
                    <br></br>StocksFC Performance is the result of my passion for fantasy football and a need for more insights on the StocksFC project and players. I hope to offer a user-friendly and intuitive platform that can help StocksFC investors worldwide make the most of their game and portfolio.
                    <br></br>
                    <br></br>Thank you for using StocksFC Performance, and I look forward to helping you take your StocksFC journey in the best possible way.
                    <br></br>
                    <br></br>PS: As a Sorare collector and football fan, my dream is to complete an album of Mainz 05 and Liverpool cards in Sorare. With StocksFC it is very similar. I want to see many Liverpool and Mainz 05 players in my portfolio. So I am also looking forward to the expansion to other leagues.
               </div>
            </div>
            <div class="featured-image">
                <a href="https://sorarescout.com/resources/sorare-card-maker/" target="_blank" rel="noopener noreferrer"><img src={'https://sorare-album.com/assets/SorareCard.png'} alt="about me picture"/> </a>
            </div>
        </div>
        </>  
    );
};

export default About;