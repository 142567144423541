import React, {useState, useEffect} from 'react';
import {useParams, useLocation, Link} from 'react-router-dom';
import Player from '../Players/Player';
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';

function ClubOverview() {

  const [data, setData] = useState([])
  var [loaded, setLoaded] = useState(false)

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const resetFilter = () => {
    setGlobalFilterValue('');
  };

  useEffect(() => {
      fetch(`https://noawag.de/public/get/allclubs`).then(
        res => res.json()
      ).then(
        data =>{
          console.log(data)
          setData(data)
          setLoaded(true)
        }
      )
  }, [])

  const filteredData = data.filter((rowData) => {
    const globalFilterFields = ['name','leagueName'];
    return globalFilterFields.some((field) =>
      String(rowData[field]).toLowerCase().includes(globalFilterValue.toLowerCase())
    );
  });

  const renderComps = () => {
    return filteredData.map(comp =>      
      <div class="col-sm-6 mb-2">
        <div class="card round_corners">
          <div class="card-body">
            <h5 class="card-title">{comp.name}</h5>
            <p class="card-text">{comp.leagueName}</p>
            {comp.pictureUrl ? <img className='compflag' src={comp.pictureUrl} alt='club-logo'/> : <img className='compflag' src={"https://noawag.de/assets/placeholder2.png"} alt='club-logo'/>}
            <Link to={`/club/${comp.slug}`}><a class="btn btn-primary">See players</a></Link>
          </div>
        </div>
      </div>
      )      
  }

  return (
    <div className='mainbody'>
      <p className='headline_comp bold'>Clubs</p>

      <div className="search-bar-container table_gap">
        <input
          type="text"
          className="search-bar-input"
          value={globalFilterValue}
          onChange={onGlobalFilterChange} 
          placeholder="Search club/league"/>
        <button className="reset-filter-button" onClick={resetFilter}>Reset Filter</button>     
      </div>

      <div className='row frame_gap'>
        {loaded ? 
          renderComps() 
          :
          <Spinner className='spinner-centered' animation="border" size="xxl"/> 
        }
      </div>

    </div>          
  );

}

export default ClubOverview;