import React, {useState} from 'react'
import { Link } from "react-router-dom";
import logo from '../Styles/Images/SorareFinder_logo.png';

const Navbar = () => {

    const [isCollapsed, setIsCollapsed] = useState(true);
    
    const handleNavItemClick = () => {
        if (window.innerWidth <= 991) {
        setIsCollapsed(true);
        }
    };
    
    return (
        <>
        <nav className={`navbar navbar-expand-lg nav_bg navbar-dark${isCollapsed ? '' : ' show'}`}>
            <div className="container-fluid py-2">
            <Link className="navbar-brand" to="/" onClick={handleNavItemClick}>
                <img src={"https://noawag.de/assets/logo_navbar.png"} className='logo-navbar' alt="Logo" />
                StocksFC Performance
            </Link>
            <button
                className={`navbar-toggler${isCollapsed ? '' : ' collapsed'}`}
                type="button"
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            {/* means */}
            <div className={`collapse navbar-collapse${isCollapsed ? '' : ' show'}`} id="navbarNav">
                <ul className="navbar-nav ms-auto nav_ul align-items-center">
                <li className="nav-item">
                    <Link className="nav-link" to="/competitions" onClick={handleNavItemClick}>
                    Competitions
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/clubs" onClick={handleNavItemClick}>
                    Clubs
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/players" onClick={handleNavItemClick}>
                    Players
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/live" onClick={handleNavItemClick}>
                    Games
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link className="nav-link" to="/inplayrewards" onClick={handleNavItemClick}>
                    Inplay Rewards
                    </Link>
                </li>   */}              
                {/* <li className="nav-item">
                    <Link className="nav-link" to="/experiments" onClick={handleNavItemClick}>
                    Rewards
                    </Link>
                </li> */}
                {/* Dropdown for later */}
                {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item dropdown" href="/">Action 1</a></li>
                        <li><a className="dropdown-item dropdown" href="/">Action 2</a></li>
                        <li><a className="dropdown-item dropdown" href="/">Action 3</a></li>
                    </ul>
                </li> */}
                {/* Login/Sign Up Buttons for later */}
               {/*  <div className="mx-3">
                    <button type="button" className="btn1 mx-2">Login</button>
                    <button type="button" className="btn2 mx-2">Sign Up</button>
                </div> */}
                {/* Switch for later or somewhere else*/}
                {/* <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                    <label className="form-check-label" for="flexSwitchCheckDefault">Dark Mode</label>
                </div> */}
                </ul>
            </div>
            {/* end */}
            </div>
        </nav>
        </>
    );
}

export default Navbar