import React from 'react';
import '../Styles/Impress.css'

const Impress = () => {
    return (
        <div className='impress-div'>
            <p className='impress-headline'><strong>Impress</strong></p>

            <p className='impress-smallheadline'>Creator:</p>
            <p className='impress-content'>Noah Wagner</p>
            <p className='impress-content'>Mainzerstraße 12</p>
            <p className='impress-content'>55278 Mommenheim</p>

            <p className='impress-smallheadline'>Contact:</p>
            <p className='impress-content'>Phone: upon request</p>
            <p className='impress-content'>E-Mail: info@stocksfcperformance.com</p>
            <p className='impress-content'>Website: www.stocksfcperformance.com</p>
        </div>
    );
};

export default Impress;