import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Searchbar from '../../Material/Searchbar';
import '../../Styles/Player.css'
import Player from './Player';
import Spinner from 'react-bootstrap/Spinner';
import { ColorRing } from 'react-loader-spinner';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

function PlayerFuture() {

  var params = useParams();
  const player = params["player"]

  const [data, setData] = useState()
  var [loaded, setLoaded] = useState(false)

  const [openItem, setOpenItem] = useState(null);

  const handleToggle = () => {
    setOpenItem(!openItem)
  };

  function formatGameDateTime(inputDateTime) {
    // Create a Date object using the input string
    const originalDateTime = new Date(inputDateTime);
  
    // Extract day, month, year, hours, and minutes from the Date object
    const day = originalDateTime.getDate().toString().padStart(2, '0');
    const month = (originalDateTime.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = originalDateTime.getFullYear();
    const hours = originalDateTime.getHours().toString().padStart(2, '0');
    const minutes = originalDateTime.getMinutes().toString().padStart(2, '0');
  
    // Create the formatted string
    const formattedDateTime = `${day}.${month}.${year} ${hours}:${minutes}`;
  
    return formattedDateTime;
  }

  useEffect(() => {
    const fetchWallet = async () => {
      await fetch(`https://vercel-express-blue.vercel.app/api/v1/player/futureGames/${player}/5`).then(
        res => res.json()
      ).then(
        data =>{
          console.log(data) 
          setData(data.player.football.player)
          setLoaded(true)
        }
      )
    }

    fetchWallet()
    }, [])  
    
    {/* 
      - Verletzung/Suspension...
      - Born on (age)
      - Ende des Vertrags
      - alle competitions des Vereins
      - Performance Durchschnitt
      - next game (next games)
      
      - evtl. in Zukunft tabs mit preis, performance, 
    */}

    const renderGames = () => {
      if(data!= undefined){
        return (
          <>
          <div class="col-sm-10 mb-2 player_general_card">
            <div class="card round_corners">
              <div class="card-body">
                <h1 class="card-title">Next games <Button onClick={() => handleToggle()}>Show</Button></h1>
            
                 {data.futureGames.nodes.map((game) => 
                  <>
                    <Collapse in={openItem}>
                    <div>
                    <hr></hr>
                    <div><img src={game.homeTeam.pictureUrl} alt={game.homeTeam.name} className="w-6rem shadow-2 border-round img_table_players"/> - <img src={game.awayTeam.pictureUrl} alt={game.awayTeam.displayName} className="w-6rem shadow-2 border-round img_table_players"/></div>
                    <div>{game.homeTeam.name} - {game.awayTeam.name}</div>
                    <div>{formatGameDateTime(game.date)}</div>
                    <div>{game.competition.name}</div>
                    </div>
                    </Collapse>
                  </>
                )}
              </div>
            </div>
          </div>
          </>
        )                    
      } 
    }
   
    return (
      <div className='player_general'> 
        {renderGames()}
      </div>
    )

}

export default PlayerFuture;