import React from 'react'

const Footer = () => {
    return (
        <>            
            <div className="Footer">
                <div className="container">
                    <hr></hr>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-12 ft-2">
                            <h5>StocksFC Performance</h5>
                            <ul>
                                <li className="nav-item">
                                    <a className="foot-link" href="/about"> About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="foot-link" href="/support"> Support</a>
                                </li>
                            </ul>                               
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-2">
                            <h5>Help</h5>
                            <ul>
                                <li className="nav-item">
                                    <a className="foot-link" href="/contact"> Contact</a>
                                </li>
                                <li className="nav-item">
                                    <a className="foot-link" href="/help"> FAQ</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-3">
                            <h5>Share</h5>
                            <p><i className="fa-solid fa-envelope"></i>Mail</p>
                            <p><i className="fa-brands fa-instagram"></i>Instagram</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Last-footer'>
                <p className='copyright'>
                    &copy;{new Date().getFullYear()} StocksFC Performance | All rights reserved
                </p>
                <p className='privacy'>
                    <a className='foot-link' href="/dataprivacy">Data Privacy</a> |  <a className='foot-link' href="/impress">Impress</a>
                </p>
            </div>
        </>
    )
}

export default Footer